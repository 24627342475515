const hamburgerMenu = document.getElementById('js-menu-btn');
const menuContentsBg = document.getElementById('js-menuContentsBg');
const root = document.documentElement;

if (hamburgerMenu != null) {
  hamburgerMenu.addEventListener('click', e => {
    const target = e.currentTarget;
    if (target.classList.contains('-open')) {
      target.classList.remove('-open');
      target.classList.add('-close');
      root.classList.add('menu-close');
      root.classList.remove('menu-open');
    } else {
      target.classList.remove('-close');
      target.classList.add('-open');
      root.classList.add('menu-open');
      root.classList.remove('menu-close');
    }
  });

  menuContentsBg.addEventListener('click', () => {
    hamburgerMenu.classList.remove('-open');
    hamburgerMenu.classList.add('-close');
    root.classList.add('menu-close');
    root.classList.remove('menu-open');
  });
  // menuContents.addEventListener('click', e => {
  //   e.preventDefault();
  //   hamburgerMenu.classList.remove('-open');
  //   hamburgerMenu.classList.add('-close');
  //   root.classList.add('menu-close');
  //   root.classList.remove('menu-open');
  // });
}
